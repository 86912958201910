import Vue from 'vue'
import store, { getDefaultLanguage } from '../store'
import VueI18n from 'vue-i18n'
import en from './en'
import zh_CN from './zh-CN'
import th from './th'
import es from './es'

Vue.use(VueI18n)

const i18n = new VueI18n({
  // fix: fallback to 'en-US' if not found any language
  fallbackLocale: getDefaultLanguage() || 'en-US',
  locale: store.state.language,
  messages: {
    en,
    'zh-CN': zh_CN,
    th,
    es
  }
})

export default i18n

export const languages = [
  {
    value: 'zh-CN',
    name: '中文'
  },
  {
    value: 'en',
    name: 'English'
  },
  {
    value: 'th',
    name: 'ภาษาไทย'
  },
  {
    value: 'es',
    name: 'Español'
  }
]
